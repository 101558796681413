import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'

const SocketContext = React.createContext()

const SocketProvider = ({ children }) => {
  // eslint-disable-next-line no-undef
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL
  const { token, user } = useSelector((state) => state.auth)
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    if (token && user) {
      setSocket(
        io(ENDPOINT, {
          extraHeaders: {
            Authorization: token,
            users_id: user.id,
          },
        })
      )
    }
  }, [token, user])

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}

export { SocketContext, SocketProvider }
