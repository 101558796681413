import i18n from './i18n'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'

import './index.css'
import './css/style.css'
import './css/custom.css'
import './css/permissions.css'
import './css/upload.css'
import './css/freelance.css'
import './css/vendor.css'
import './css/custom_modals.css'
import './css/additional.scss'
import './css/updated.scss'
import './css/updates.scss'
import './css/newcss.scss'
import './css/loader.scss'
import './css/_loading-bar.scss'
import './css/fontawesome/css/all.css'
import 'react-responsive-modal/styles.css'
import 'react-tabs/style/react-tabs.css'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'

import './css/new_table.scss'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import FormLoading from './components/common/blocks/FormLoading'
import { SocketProvider } from './socketContext'

const App = React.lazy(() => import('./App'))
const reduxStore = store

// Corrected the type casting
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={reduxStore}>
    <SocketProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<FormLoading loadingText={'loading...'} />}>
            <App />
          </Suspense>
        </I18nextProvider>
      </BrowserRouter>
    </SocketProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
