export const replaceByObjectKey = (arr, payload, key) => {
  const idx = arr.findIndex((item) => item[key] === payload[key] && item[key])
  if (idx !== -1) arr[idx] = payload
  if (idx < 0) arr.push(payload)
  return arr
}
export const swapElements = (arr, x, y) => {
  ;[arr[x], arr[y]] = [arr[y], arr[x]]

  return arr
}
