const Spinner = ({ style }) => {
  return (
    <div className="spinner" style={style}>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
    </div>
  )
}

export default Spinner
