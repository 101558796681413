import {
  SET_ASSIGN_FILTER,
  SET_FILTERS,
  SET_OPEN_FILTERS,
  SET_DEFAULT_FILTERS,
} from '../actions/types'

/* const filters = {}
  name: ['value']
} */

const initialState = {
  isOpen: false,
  assign_filter: false,
  filters: {
    user_type: ['employee', 'freelancer'],
  },
}

const filtersReducer = function (state = initialState, action) {
  const filters = { ...state.filters }
  let newValues = filters[action.name] || []

  switch (action.type) {
    case SET_OPEN_FILTERS:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case SET_ASSIGN_FILTER:
      //const idx = newValues.indexOf(action.value)
      //newValues.push(action.value)
      /*       if (idx >= 0) {
        newValues.splice(idx, 1)
      } else {
        
      } */

      filters[action.name] = [action.value]

      return {
        ...state,
        filters,
      }
    case SET_FILTERS:
      const idx = newValues.indexOf(action.value)
      if (action.name === 'languages_to_id' || action.name === 'languages_from_id') {
        newValues = [action.value]
      } else {
        if (idx >= 0) {
          newValues.splice(idx, 1)
        } else {
          newValues.push(action.value)
        }
      }

      filters[action.name] = newValues

      return {
        ...state,
        filters,
      }
    case SET_DEFAULT_FILTERS: {
      return {
        ...state,
        filters: {
          user_type: ['employee', 'freelancer'],
        },
      }
    }
    default:
      return state
  }
}
export default filtersReducer
