import {
  SET_LANGUAGES,
  SET_CURRENCIES,
  SET_SERVICES,
  SET_SHOW_MODAL_FORM,
  SET_APP_LANGUAGES,
  SET_MODAL_DATA_SHOW,
  SET_MODAL_DATA_URL,
  SET_RELOAD,
  SET_MENU,
  SET_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS_COUNT,
  SET_UNREAD_MESSAGES_COUNT,
  SET_CHAT_TAGS,
  SET_NAVIGATION_HISTORY,
  SET_TABLES_SWITCHER,
} from '../actions/types'

const initialState = {
  languages: false,
  app_languages: false,
  currencies: false,
  services: false,
  showModalForm: false,
  reload: false,
  modalData: {
    showModal: false,
    modalUrl: null,
  },
  menu: false,
  tablesSwitcher: false,
  notifications: false,
  unreadNotificationsCount: null,
  unreadMessagesCount: 0,
  chat_tags: {
    tags: [],
    rooms: [],
  },
  isDevNotified: false,
  navigationHistory: [],
}

const initReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      }
    case SET_APP_LANGUAGES:
      return {
        ...state,
        app_languages: action.payload,
      }
    case SET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      }
    case SET_SERVICES:
      return {
        ...state,
        services: action.payload,
      }
    case SET_SHOW_MODAL_FORM:
      return {
        ...state,
        showModalForm: action.payload,
      }
    case SET_MODAL_DATA_SHOW:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          showModal: action.showModal,
        },
      }
    case SET_MODAL_DATA_URL: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          modalUrl: action.url,
        },
      }
    }
    case SET_RELOAD: {
      return {
        ...state,
        reload: !state.reload,
      }
    }
    case SET_MENU: {
      return {
        ...state,
        menu: action.payload,
      }
    }
    case SET_TABLES_SWITCHER: {
      return {
        ...state,
        tablesSwitcher: action.payload,
      }
    }
    case SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      }
    }
    case SET_UNREAD_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        unreadNotificationsCount: action.payload,
      }
    }
    case SET_CHAT_TAGS: {
      return {
        ...state,
        chat_tags: {
          ...state.chat_tags,
          tags: action.payload.tags,
          rooms: action.payload.rooms,
        },
      }
    }
    case SET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: action.count,
      }
    case 'SET_DEV_NOTIFIED':
      return {
        ...state,
        isDevNotified: action.payload,
      }
    case SET_NAVIGATION_HISTORY:
      return {
        ...state,
        navigationHistory: action.payload,
      }
    default:
      return state
  }
}
export default initReducer
